define('web/controllers/user-options', ['exports', 'ember'], function (exports, _ember) {
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller(),

    actions: {
      saveUserOptions: function saveUserOptions() {
        var _this = this;

        this.get('application').send('showLoader');

        if (this.get('model.phLotNameSource')) {
          this.set('model.userOptions.lotNameSource', 'ph_lot_name');
        } else {
          this.set('model.userOptions.lotNameSource', 'external_section_name');
        }

        this.get('model.userOptions').save().then(function () {
          _this.get('application').send('hideLoader');
          _this.flashMessages.success('User Options updated successfully.');
        })['catch'](function (err) {
          _this.get('application').send('hideLoader');
          _this.flashMessages.danger(err.message);
        });
      },

      updateExternalValidationTypeOption: function updateExternalValidationTypeOption(newVal, checked) {
        if (checked) {
          return this.get('model.userOptions.externalValidationTypes').pushObject(newVal);
        }

        this.get('model.userOptions.externalValidationTypes').removeObject(newVal);
      },

      updateUserOption: function updateUserOption(toggleValue, checked, key) {
        if (toggleValue === 'digitalReceipt' && this.get('model.userOptions.digitalReceipt') === true) {
          this.set('model.userOptions.digitalReceiptPrepaids', false);
          return this.toggleProperty('model.userOptions.digitalReceipt');
        }

        if (typeof toggleValue === 'string') {
          this.toggleProperty('model.userOptions.' + toggleValue);

          key = toggleValue;
          checked = this.get('model.userOptions.' + toggleValue);
        }

        this.set('model.userOptions.' + key, checked);
      }
    }
  });
});