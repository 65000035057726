define('web/components/edit-pricing-cluster', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['default-profile', 'animated', 'fadeIn'],
    addPricing: 'addPricing',
    deletePricing: 'deletePricing',
    sortOptions: ['createdAt:asc'],

    modalStyle: computed('isModal', function () {
      if (this.get('isModal') === 'isModal') {
        return 'margin: 1em 1em;'.htmlSafe();
      }
    }),

    sortedPricings: computed('pricings.[]', {
      get: function get() {
        var pricings = this.get('pricings');
        if (pricings) {
          return pricings;
        }
      }
    }),

    pricingsArray: computed.sort('sortedPricings', 'sortOptions'),

    actions: {
      addPricing: function addPricing(tag, item) {
        this.attrs.addPricing(tag, item);
      },

      editPricing: function editPricing(pricing) {
        this.attrs.editPricing(pricing);
      },

      deletePricing: function deletePricing(pricing) {
        this.attrs.deletePricing(pricing);
      }
    }
  });
});