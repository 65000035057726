define('web/components/ajax-file-upload', ['exports', 'ember', 'ic-ajax', 'web/config/environment'], function (exports, _ember, _icAjax, _webConfigEnvironment) {
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['image-uploader'],
    classNameBindings: ['roundImage:round'],
    attributeBindings: ['bgImage:style'],

    openModal: 'openModal',
    invalidateModel: 'invalidateModel',

    isLoading: false,
    finishedLoading: false,
    newFileData: null,

    input: (function () {
      return this.$('input')[0];
    }).property(),

    readFile: function readFile() {
      var _this = this;

      var i = this.get('input');

      if (i.files && i.files[0]) {
        var r = new FileReader();

        r.onloadstart = function () {
          _this.set('isLoading', true);
        };

        r.readAsDataURL(i.files[0]);
      }
    },

    actions: {
      newFileData: function newFileData(fileData) {
        var _this2 = this;

        this.set('newFileData', fileData);
        this.readFile();

        var obj = this.get('fileObj');
        obj.file = document.getElementById('ajax-file-uploader').files[0];

        var formData = new FormData();

        for (var key in obj) {
          formData.append(key, obj[key]);
        }

        if (obj.file) {
          (0, _icAjax['default'])({
            method: 'POST',
            url: '' + (_webConfigEnvironment['default'].apiUrl + this.get('endpoint')),
            contentType: false,
            processData: false,
            data: formData,
            crossDomain: true,
            xhrFields: {
              withCredentials: true
            }
          }).then(function (resp) {
            _this2.set('finishedLoading', true);

            run.later(function () {
              _this2.set('finishedLoading', false);
              _this2.set('fileData', null);
              _this2.sendAction('openModal', 'file-upload-complete', resp);
            }, 1000);
          })['catch'](function (err) {
            _this2.flashMessages.danger(err.jqXHR.responseJSON.message);
          })['finally'](function () {
            _this2.set('isLoading', false);
            if (_ember['default'].isPresent(_this2.get('refresh'))) {
              _this2.sendAction('invalidateModel');
            }
          });
        }
      }
    }
  });
});