define('web/routes/tag-pricing/details', ['exports', 'ember', 'web/mixins/reset-scroll', 'web/helpers/get-min-max'], function (exports, _ember, _webMixinsResetScroll, _webHelpersGetMinMax) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsResetScroll['default'], {

    model: function model() {
      var _this = this;

      return this.store.query('lot', { tagId: this.modelFor('tag-pricing').get('id') }).then(function (lots) {
        return _ember['default'].RSVP.all(lots.map(function (lot) {
          return lot.get('pricingIds').then(function (pricings) {
            var listPricings = [];

            pricings.forEach(function (pricing) {
              listPricings.push(pricing.get('price'));
            });

            return {
              lot: lot,
              id: lot.get('id'),
              name: lot.get('name'),
              pricings: lot.get('pricingIds'),
              tag: _this.modelFor('tag-pricing'),
              minPrice: (0, _webHelpersGetMinMax.getMinMax)(listPricings, 'min') / 100,
              maxPrice: (0, _webHelpersGetMinMax.getMinMax)(listPricings, 'max') / 100,
              'default': lot.get('pricingIds.length') === 0 ? true : false
            };
          });
        }));
      });
    },

    setupController: function setupController(controller, model) {
      var currentModel = model;
      this._super(controller, this.modelFor('tag-pricing'));

      controller.set('lots', currentModel);
      controller.set('initialLots', currentModel);
    },

    actions: {
      invalidateModel: function invalidateModel() {
        this.modelFor('tag-pricing').reload();
      }
    }
  });
});