define('web/routes/event-parking/details', ['exports', 'ember', 'web/mixins/reset-scroll'], function (exports, _ember, _webMixinsResetScroll) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var get = _ember['default'].get;
  var RSVP = _ember['default'].RSVP;

  var options = {
    findAllMatches: true,
    shouldSort: true,
    threshold: 0.1,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['name']
  };

  exports['default'] = _ember['default'].Route.extend(_webMixinsResetScroll['default'], {

    model: function model() {
      var _this = this;

      var event = this.modelFor('event-parking');
      var lotsSellable = get(event, 'eventOptions.lotsSellable');

      return RSVP.hash({
        event: this.store.find('event', event.get('id')),
        landmark: event.get('landmarkId'),
        lots: this.store.query('lot', { tagId: event.get('tagId.id') })
      }).then(function (hash) {
        var lots = hash.lots;
        var landmark = hash.landmark;

        return Promise.all(lots.map(function (lot) {
          lotsSellable.forEach(function (sellableLot) {
            if (sellableLot.get('id') === lot.get('id')) {
              lot.set('sellable', true);
            }
          });

          return _ember['default'].RSVP.hash({
            pricings: _this.store.query('pricing', {
              lotId: lot.get('id'),
              tagId: event.get('tagId.id')
            })
          }).then(function (resp) {
            var prices = resp.pricings.map(function (p) {
              return p.get('price');
            });

            return _ember['default'].Object.create({
              lot: lot,
              tag: event.get('tagId'),
              name: lot.get('name'),
              pricings: resp.pricings,
              'default': prices.length === 0 ? true : false,
              min: Math.min.apply(Math, _toConsumableArray(prices)) / 100,
              max: Math.max.apply(Math, _toConsumableArray(prices)) / 100
            });
          });
        })).then(function (l) {
          return {
            event: hash.event,
            eventOptions: event.get('eventOptions'),
            landmark: landmark,
            lots: l,
            lotsSellable: lotsSellable,
            initialLots: l
          };
        });
      });
    },

    actions: {
      willTransition: function willTransition() {
        // Reset sellable lot sellable flag.
        this.controller.model.lots.forEach(function (lot) {
          lot.lot.set('sellable', false);
        });
      },

      invalidateModel: function invalidateModel() {
        this.refresh();
      }
    }
  });
});