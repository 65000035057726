define('web/routes/additional-charges', ['exports', 'ember', 'ic-ajax', 'web/config/environment', 'web/mixins/build-params', 'web/helpers/tax-math'], function (exports, _ember, _icAjax, _webConfigEnvironment, _webMixinsBuildParams, _webHelpersTaxMath) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var math = (0, _webHelpersTaxMath.additionalChargesMath)();

  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var _this = this;

      var promises = [this.store.query('landmark', (0, _webMixinsBuildParams.filterParams)({ operatorId: this.get('currentUser.id') })), (0, _icAjax['default'])({
        method: 'GET',
        url: _webConfigEnvironment['default'].platformAPIUrl + '/api/v1/transactions/additional-charges',
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      }), (0, _icAjax['default'])({
        method: 'GET',
        url: _webConfigEnvironment['default'].platformAPIUrl + '/api/v1/transactions/additional-charges/user-options',
        contentType: 'application/json',
        dataType: 'json',
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      })];

      return _ember['default'].RSVP.all(promises).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 3);

        var landmarks = _ref2[0];
        var additionalCharges = _ref2[1];
        var userOptions = _ref2[2];

        var landmarksToFilterOut = [];

        _this.set('additionalChargesEnabled', userOptions.additionalChargesEnabled);

        if (additionalCharges.defaultSettings.applyToAllLandmarks && additionalCharges.additionalCharges && additionalCharges.additionalCharges.length > 0) {

          var tax = additionalCharges.additionalCharges.find(function (record) {
            return record.classification === "TAX";
          });
          var fees = additionalCharges.additionalCharges.filter(function (record) {
            return record.classification !== "TAX";
          });

          _this.set('acrossLocations', _ember['default'].Object.create({
            enabled: true,
            tax: _ember['default'].Object.create({
              name: tax ? tax.name : "Tax",
              type: tax && math.setType(tax.type),
              placeholder: tax && math.setPlaceholder(tax.type),
              value: tax && math.convertIn(tax.type, tax.value),
              hasValueError: false
            }),
            fees: _ember['default'].Object.create({
              type: _ember['default'].Object.create({ id: "FIXED_RATE", name: "Amount" }),
              placeholder: "$",
              records: _ember['default'].A(fees.map(function (fee) {
                return _ember['default'].Object.create({
                  name: fee.name,
                  placeholder: math.setPlaceholder(fee.type),
                  type: math.setType(fee.type),
                  value: math.convertIn(fee.type, fee.value),
                  hasValueError: false,
                  hasNameError: false
                });
              }))
            })
          }));

          _this.set('perLocation', _ember['default'].Object.create({
            records: _ember['default'].A([])
          }));
        } else {
          _this.set('perLocation', _ember['default'].Object.create({
            records: _ember['default'].A([])
          }));

          if (additionalCharges.landmarks && additionalCharges.landmarks.length > 0) {
            additionalCharges.landmarks.forEach(function (landmark) {
              landmarksToFilterOut.push(landmark.id);

              var data = _ember['default'].Object.create({
                landmark: _ember['default'].Object.create({ id: landmark.id, name: landmark.name }),
                enabled: false,
                tax: null
              });

              var fees = _ember['default'].Object.create({
                type: _ember['default'].Object.create({ id: "FIXED_RATE", name: "Amount" }),
                placeholder: "$",
                records: _ember['default'].A()
              });

              landmark.additionalCharges.forEach(function (charge) {
                if (charge.classification === 'TAX') {
                  data.tax = _ember['default'].Object.create({
                    name: "Tax",
                    type: math.setType(charge.type),
                    placeholder: math.setPlaceholder(charge.type),
                    value: math.convertOut(charge.type, charge.value),
                    hasValueError: false
                  });
                } else if (charge.classification === 'FEE') {
                  fees.records.pushObject(_ember['default'].Object.create({
                    name: charge.name,
                    type: math.setType(charge.type),
                    placeholder: math.setPlaceholder(charge.type),
                    value: math.convertOut(charge.typ, charge.value),
                    hasValueError: false,
                    hasNameError: false
                  }));
                }
              });

              data.set('fees', fees);

              _this.get('perLocation.records').pushObject(_ember['default'].Object.create(data));
            });
          }

          _this.set('acrossLocations', _ember['default'].Object.create({
            enabled: false,
            tax: _ember['default'].Object.create({
              name: "Tax",
              type: _ember['default'].Object.create({ id: "PERCENTAGE", name: "Percentage" }),
              placeholder: "%",
              value: null,
              hasValueError: false
            }),
            fees: _ember['default'].Object.create({
              type: _ember['default'].Object.create({ id: "FIXED_RATE", name: "Amount" }),
              placeholder: "$",
              records: _ember['default'].A()
            })
          }));
        }

        if (!additionalCharges.defaultSettings.transactionTypes) {
          additionalCharges.defaultSettings.transactionTypes = [];
        }

        _this.set('transactionTypes', _ember['default'].Object.create({
          cash: additionalCharges.defaultSettings.transactionTypes.includes("cash"),
          credit: additionalCharges.defaultSettings.transactionTypes.includes("credit"),
          payment: additionalCharges.defaultSettings.transactionTypes.includes("payment")
        }));

        return _ember['default'].Object.create({
          transactionTypes: _this.get('transactionTypes'),
          perLocation: _this.get('perLocation'),
          acrossLocations: _this.get('acrossLocations'),
          additionalChargesEnabled: _this.get('additionalChargesEnabled'),
          applyForUpcomingEvents: _this.get('applyForUpcomingEvents'),
          landmarks: landmarks.map(function (l) {
            return { id: l.get('id'), name: l.get('name') };
          }).filter(function (l) {
            return !landmarksToFilterOut.includes(l.id);
          })
        });
      })['catch'](function (err) {
        console.log("Error: ", err);
        _this.flashMessages.danger("Unable to retreive additional charges.");
      });
    }
  });
});