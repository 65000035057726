define('web/components/tag-cluster-item', ['exports', 'ember', 'web/helpers/modifier-price'], function (exports, _ember, _webHelpersModifierPrice) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['tag-cluster__item'],
    modifyPricingCluster: 'modifyPricingCluster',
    sortOptions: ['createdAt:asc'],

    clusterItem: computed('tag', 'lot', 'clusterOf', {
      get: function get() {
        return this.get('clusterOf') === 'tag' ? this.get('tag') : this.get('lot');
      }
    }),

    sortedPricings: computed('model', 'clusterItem.pricingIds.@each.price', 'priceFloat', {
      get: function get() {
        var _this = this;

        var pricings = this.get('clusterItem.pricingIds');

        if (_ember['default'].isPresent(pricings)) {
          // If we dont want to use the price modifier price. Example (lot level)
          if (this.get('clearModifiedPrice')) {
            pricings.forEach(function (prc) {
              return prc.set('modifiedPrice', null);
            });

            return pricings;
          }
          // If we are using the modifier price. Example (event level)
          if (_ember['default'].isPresent(this.get('priceFloat'))) {
            pricings.forEach(function (prc) {
              return prc.set('modifiedPrice', (0, _webHelpersModifierPrice.modifierPrice)(prc.get('price'), _this.get('priceFloat')));
            });
            return pricings.sortBy('id');
          }

          return pricings;
        }
      }
    }),

    pricingsArray: computed.sort('sortedPricings', 'sortOptions'),

    actions: {
      modifyPricingCluster: function modifyPricingCluster(tag, lot, type, title) {
        this.sendAction('modifyPricingCluster', tag, lot, type, title);
      }
    }
  });
});