define('web/mixins/edit-pricings', ['exports', 'ember', 'web/routes/lot/pricing'], function (exports, _ember, _webRoutesLotPricing) {
  exports['default'] = _ember['default'].Mixin.create({
    deletedPricings: _ember['default'].A(),

    actions: {
      addPricing: function addPricing(tag, lot) {
        var isDefault = tag || lot ? false : true;

        var pricing = this.store.createRecord('pricing', {
          name: 'Pricing Name',
          price: 1000,
          userDefault: isDefault,
          tagId: tag || null,
          lotId: lot || null,
          createdAt: moment().toDate(),
          quantity: 1
        });

        // Add to editedPricings array.
        this.get('clusterPricings').pushObject(pricing);
      },

      editPricing: function editPricing(pricing) {
        // If the pricing is already in the array then update it to the new one.
        // else add it to the edited pricings array.
        if (this.get('clusterPricings').includes(pricing)) {
          this.get('clusterPricings').forEach(function (p) {
            if (p === pricing) {
              pricing = p;
            }
          });
        } else {
          this.get('clusterPricings').pushObject(pricing);
        }
      },

      deletePricing: function deletePricing(pricing) {
        var _this = this;

        this.get('clusterPricings').forEach(function (p) {
          if (p === pricing) {
            // Remove pricing from pricings array.
            _this.get('clusterPricings').removeObject(p);

            // Story the pricing in a delete pricing array for delete on submit.
            _this.get('deletedPricings').pushObject(p);
          }
        });
      },

      submitPricings: function submitPricings() {
        var _this2 = this;

        var pricings = _ember['default'].A();

        this.get('clusterPricings').forEach(function (pricing) {
          if (pricing.get('hasDirtyAttributes')) {
            pricings.pushObject(pricing.save());
          }
        });

        return Promise.all(this.get('deletedPricings').map(function (deletePricing) {
          return deletePricing.destroyRecord();
        })).then(function () {
          // Reset the deletedPricings Array.
          _this2.set('deletedPricings', _ember['default'].A());

          // Loop over all the edited pricings and save.
          return Promise.all(pricings).then(function (resp) {
            _this2.flashMessages.success('Pricing(s) were updated successfully.');
            _this2.send('invalidateModel');
            _this2.send('closeModal', 'modify-pricing');
          })['catch'](function (err) {
            _this2.flashMessages.danger(err.message);
          });
        })['catch'](function (err) {
          _this2.flashMessages.danger(err.message);
        });
      },

      modifyPricingCluster: function modifyPricingCluster(tag, lot, type, title) {
        var itemModel = { title: title, tag: tag, lot: lot };
        this.get('modifyPricing').setProperties({
          model: itemModel,
          type: type
        });
        this.send('openModal', 'modify-pricing');
      }
    }
  });
});