define('web/models/pricing', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    price: attr('number'),
    count: attr('number'),
    userDefault: attr('boolean'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    quantity: attr('number'),

    lotId: belongsTo('lot', { async: true }),
    tagId: belongsTo('tag', { async: true })
  });
});