define('web/models/user-option', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = _emberData['default'].Model.extend({
    receiptReprints: attr('boolean'),
    rejectIfDuplicate: attr('boolean'),
    rejectIfInvalid: attr('boolean'),
    rejectInvalidLots: attr('boolean'),
    offlineCash: attr('boolean', { defaultValue: true }),
    offlineCredit: attr('boolean'),
    offlineExemptions: attr('boolean', { defaultValue: true }),
    offlinePermits: attr('boolean', { defaultValue: true }),
    offlinePrepaids: attr('boolean', { defaultValue: true }),
    digitalReceiptPrepaids: attr('boolean'),
    displayLotUtilization: attr('boolean'),
    printExemptions: attr('boolean'),
    printPermits: attr('boolean'),
    printPrepaids: attr('boolean'),
    printPayments: attr('boolean'),
    displayOrderDetails: attr('boolean'),
    disableCash: attr('boolean'),
    digitalReceipt: attr('boolean'),
    editEvent: attr('boolean'),
    eventCreation: attr('boolean'),
    printPricingInitial: attr('boolean'),
    enableSpotNumber: attr('boolean'),
    lotNameSource: attr('string'),
    scanAndGoAvailable: attr('boolean'),
    scanAndConfirmAvailable: attr('boolean'),
    continuousAvailable: attr('boolean'),
    externalValidationTypes: hasMany('external-validation-type', { async: true }),
    cashReceiptsWithoutNetwork: attr('boolean')
  });
});