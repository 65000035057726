define('web/controllers/tag-pricings', ['exports', 'ember', 'web/mixins/records-search', 'web/mixins/loading-model', 'web/mixins/load-more', 'web/mixins/edit-pricings'], function (exports, _ember, _webMixinsRecordsSearch, _webMixinsLoadingModel, _webMixinsLoadMore, _webMixinsEditPricings) {
  var filter = _ember['default'].computed.filter;
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordsSearch.ControllerSearchMixin, _webMixinsLoadingModel.ControllerLoadingMixin, _webMixinsLoadMore.ControllerLoadMore, _webMixinsEditPricings['default'], {
    queryParams: ['search', 'limit', 'offset'],
    modifyPricing: _ember['default'].inject.controller('modals.modify-pricing'),
    recordName: 'tag',
    modelName: 'model.tags',

    tagPricingCols: [{ colName: 'Name' }, {}],

    defaultPricings: filter('model.pricings', function (p) {
      return p.get('userDefault');
    }),

    actions: {
      tagTransition: function tagTransition(tagId) {
        this.transitionToRoute('tag-pricing', tagId);
      }
    }
  });
});