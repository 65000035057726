define('web/components/pricing-obj', ['exports', 'ember'], function (exports, _ember) {
  var debounce = _ember['default'].run.debounce;
  var on = _ember['default'].on;
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['ps-obj__container'],
    editedPricings: _ember['default'].A([]),
    deletePricingSelected: false,
    deletePricing: 'deletePricing',
    errorMessages: null,
    curPrice: null,

    didInitAttrs: function didInitAttrs(attrs) {
      this.set('curPrice', attrs.attrs.pricing.value.get('price'));
    },

    _saveEdits: function _saveEdits(pricing) {
      var _this = this;

      if (isNaN(pricing.get('price')) || isNaN(pricing.get('quantity'))) {
        this.flashMessages.danger('You must enter a valid number.');
      } else {
        pricing.save().then(function () {
          return _this.flashMessages.success('Successfully edited pricing');
        })['catch'](function () {
          _this.flashMessages.danger('There was an error editing this pricing');
          run(_this, _this._clearErrorSpots, pricing);
        });
      }
    },

    _clearErrorSpots: function _clearErrorSpots(pricing) {
      pricing.rollbackAttributes();
    },

    rollback: on('willDestroyElement', function () {
      if (this.get('pricing').get('hasDirtyAttributes')) {
        this.get('pricing').rollbackAttributes();
      }
    }),

    actions: {
      toggleDeletePricing: function toggleDeletePricing() {
        var pricings = this.get('pricing');
        this.toggleProperty('deletePricingSelected');
      },

      deletePricing: function deletePricing(pricing) {
        this.attrs.deletePricing(pricing);
      },

      setCents: function setCents(cents) {
        this.get('pricing').set('price', cents);
      },

      editPricing: function editPricing(pricing) {
        this.attrs.editPricing(pricing);
      },

      clearErrors: function clearErrors() {
        this.set('errorMessages', null);
      }
    }
  });
});