define('web/routes/event-parkings', ['exports', 'ember', 'underscore', 'web/mixins/reset-scroll', 'web/mixins/load-more', 'web/mixins/loading-model', 'web/mixins/records-search', 'web/mixins/build-params', 'web/helpers/get-min-max'], function (exports, _ember, _underscore, _webMixinsResetScroll, _webMixinsLoadMore, _webMixinsLoadingModel, _webMixinsRecordsSearch, _webMixinsBuildParams, _webHelpersGetMinMax) {
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Route.extend(_webMixinsLoadingModel.RouteLoadingMixin, _webMixinsRecordsSearch.RouteSearchMixin, _webMixinsLoadMore.RouteLoadMore, _webMixinsResetScroll['default'], {

    queryParams: {
      search: { refreshModel: true },
      limit: { refreshModel: true },
      offset: { refreshModel: true },
      live: { refreshModel: true }
    },

    model: function model(params) {
      var _this = this;

      // If the currentUser is not an admin query the currentUsers landmarks.
      if (!this.get('currentUser.isAdmin')) {
        var payload = {
          operatorId: this.get('currentUser.id')
        };

        if (params.search !== null) {
          payload.search = params.search;
        }

        if (params.limit !== null) {
          payload.limit = params.limit;
        }

        if (params.offset !== null) {
          payload.offset = params.offset;
        }

        if (params.live !== null) {
          payload.live = params.live;
        }

        return this.store.query('event', payload).then(function (events) {

          if (events.length === 0) {
            return;
          }

          return _ember['default'].RSVP.all(events.map(function (event) {
            return event.get('tagId').then(function (tag) {
              var listPricings = _ember['default'].A();

              return _this.store.query('pricing', { eventId: event.get('id') }).then(function (pricings) {
                pricings.forEach(function (pricing) {
                  listPricings.push(pricing.get('price'));
                });

                set(event, 'minPrice', (0, _webHelpersGetMinMax.getMinMax)(listPricings, 'min') / 100);
                set(event, 'maxPrice', (0, _webHelpersGetMinMax.getMinMax)(listPricings, 'max') / 100);
                set(event, 'tagName', tag.get('name'));
              });
            }).then(function () {
              return event;
            })['catch'](function (err) {
              console.log(err);
            });
          }));
        });
      }

      return this.store.query('event', (0, _webMixinsBuildParams.filterParams)(params)).then(function (events) {
        if (events.length === 0) {
          return;
        }

        return events;
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('initialEvents', model);
    }
  });
});