define('web/controllers/modals/modify-pricing', ['exports', 'ember', 'web/mixins/edit-pricings'], function (exports, _ember, _webMixinsEditPricings) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend(_webMixinsEditPricings['default'], {
    application: _ember['default'].inject.controller('application'),
    isModalOpen: _ember['default'].computed.alias('application.isModalOpen'),
    modifyPricing: _ember['default'].inject.controller('modals.modify-pricing'),

    clusterItem: computed('tag', 'lot', 'type', {
      get: function get() {
        return this.get('type') === 'tag' ? this.get('tag') : this.get('lot');
      }
    }),

    clusterPricings: computed('tag.pricingIds.[]', 'lot.pricingIds.[]', 'type', {
      get: function get() {
        return this.get('type') === 'tag' ? this.get('tag.pricingIds') : this.get('lot.pricingIds');
      }
    }),

    lot: computed('model', {
      get: function get() {
        var _get = this.get('model');

        var lot = _get.lot;

        return lot;
      }
    }),

    tag: computed('model', {
      get: function get() {
        var _get2 = this.get('model');

        var tag = _get2.tag;

        return tag;
      }
    })
  });
});