define('web/routes/payment-accounts/merchant-accounts', ['exports', 'ember'], function (exports, _ember) {
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return RSVP.hash({
        paymentProcessors: this.store.findAll('payment-processor'),
        paymentProcessorTypes: this.store.findAll('payment-processor-type')
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var processorTypes = [];

      model.paymentProcessorTypes.content.forEach(function (processorType) {
        if (processorType.getRecord().get('name') !== 'FAPS Ingenico') {
          processorTypes.push(processorType);
        }
      });

      model.paymentProcessorTypes.set('content', processorTypes);

      var paymentProcessors = _ember['default'].A();

      model.paymentProcessors.forEach(function (processor) {
        if (!processor.get('deleted')) {
          paymentProcessors.pushObject(processor);
        }
      });

      controller.set('paymentProcessors', paymentProcessors);
      controller.set('paymentProcessorTypes', model.paymentProcessorTypes);
    },

    deactivate: function deactivate() {
      this.controller.setProperties({
        createPaymentProcessor: false,
        modifyPaymentProcessor: false
      });
    },

    actions: {
      invalidateModel: function invalidateModel() {
        this.refresh();
      }
    }
  });
});