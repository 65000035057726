define('web/components/toggle-switch', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['toggle-switch'],
    classNameBindings: ['small'],
    selection: false,
    selectionObject: null,
    toggleSelected: 'toggleSelected',
    offColor: '#fcfcfc',
    onColor: '#606b76',
    identifier: null,

    firstPosition: null,
    secondPosition: null,

    toggleStyle: computed('onColor', 'offColor', 'selection', function () {
      if (this.get('selection')) {
        return ('background: ' + this.get('onColor')).htmlSafe();
      }
      return ('background: ' + this.get('offColor')).htmlSafe();
    }),

    actions: {
      toggleSelected: function toggleSelected() {
        if (this.get('selectionObject')) {
          this.sendAction('toggleSelected', this.get('selectionObject'));
        } else {
          if (this.get('identifier')) {
            return this.sendAction('toggleSelected', this.get('identifier'));
          }

          return this.sendAction('toggleSelected');
        }
      },
      deselect: function deselect() {
        if (this.get('identifier')) {
          return this.sendAction('toggleSelected', this.get('identifier'));
        }

        this.sendAction('toggleSelected');
      },
      select: function select() {
        if (this.get('identifier')) {
          return this.sendAction('toggleSelected', this.get('identifier'));
        }

        this.sendAction('toggleSelected');
      }
    }
  });
});