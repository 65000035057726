define('web/components/default-pricings', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['default-profile', 'animated', 'fadeIn'],

    sortedDefaultPricings: computed('pricings.[]', {
      get: function get() {
        if (this.get('pricings')) {
          var defaultPricings = this.get('pricings').filterBy('userDefault', true);
          return defaultPricings.sortBy('id');
        }
      }
    }),

    actions: {
      addPricing: function addPricing() {
        this.attrs.addPricing();
      },

      deletePricing: function deletePricing(pricing) {
        this.attrs.deletePricing(pricing);
      },

      invalidateModel: function invalidateModel() {
        this.sendAction('invalidateModel');
      }
    }
  });
});